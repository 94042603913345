import React, { ChangeEvent, useEffect, useState } from 'react';
import './Login.css';
import { logout } from '../../../services/gcalls.service';
import Checkbox from './Checkbox';
import { LoginStatus } from '../../../types';
import Logo from './images/logoGcalls.png';
import { gdebug } from '../../../lib/gdebug';
import { getMobileParrentHostNameList, setMobileParrentHost } from '../../../services/gMobileApp.service';
import { isMobile } from '../../../lib/gUtils';
import axios from 'axios';
import { logoutKeycloak } from '../../../services/keycloak.service';
import { CDN } from '../../../lib/gCdn';
import { gConst } from '../../../lib/gConst';
type Props = {
  isLogin: boolean;
  setIsLogin: any;
  userData: LoginStatus;
  setUserData: any;
  syncSip: any;
  closeLogin: any;
  updateUserInfo: any;
  signOut: any;
  keycloak: any;
};

function InfoPage({ isLogin, setIsLogin, userData, setUserData, updateUserInfo, syncSip, closeLogin, signOut, keycloak }: Props) {
  let gUserData: any = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
  gUserData = JSON.parse(gUserData);
  const [parentHostName, setParentHostName] = useState('');
  const [logo, setLogo] = useState<string>('');

  const [listParentHostName, setListParentHostName] = useState<{ [key: string]: string }>({});
  let listOptions = !!gUserData && !!gUserData.integrator && gUserData.integrator[parentHostName] && !Array.isArray(gUserData.integrator[parentHostName]) ? gUserData.integrator[parentHostName]?.info?.listOptions : [];
  // console.log("listOptions: ", listOptions)
  gdebug(3, 'listOptions: ', listOptions);
  useEffect(() => {
    setParentHostName(localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) || '');
    initStatic()

    if (isMobile(window)) {

      (async () => {
        const listHostAvailable: [
          {
            name: string;
            listDomain: [];
          },
        ] = await axios.get('https://gstatics.sgp1.digitaloceanspaces.com/Json/GcallsIntegration.json').then((response) => response.data);
        const listName = [...listHostAvailable.map((e) => e.name).flat(), 'force'];
        let parentHostNameList = getMobileParrentHostNameList(gUserData);
        parentHostNameList = parentHostNameList.filter((e: string) => {
          // filter with pattern e have scheme
          if (e.includes('://')) return false;
          const x = listName.some((ele: string) => e.includes(ele));
          return x;
        });
        const filteredListParentHostname: { [key: string]: any } = {};
        listName.forEach((name) => {
          filteredListParentHostname[name] = parentHostNameList.find((e: string) => e.includes(name));
        });
        Object.keys(filteredListParentHostname).forEach((key) => {
          if (!filteredListParentHostname[key]) {
            delete filteredListParentHostname[key];
          }
        });
        setListParentHostName(filteredListParentHostname);
        // console.log("org.chatapp ~ parentHostNameList:", filteredlistParentHostname);
      })();
    }
  }, []);

  const handleLogout = async () => {
    let result = await logout();
    signOut();
    if (result) {
      setIsLogin({
        isLogin: false,
        username: '',
        fullname: '',
      });
    }
  };

  const handleLogoutKeycloak = async () => {
    gdebug(3, 'Logout ');
    await logout(); //add login gcalls - offline agent
    signOut();
    setIsLogin({
      isLogin: false,
      username: '',
      fullname: '',
    });

    logoutKeycloak()
  };

  // const handleSyncSip = async()=>{
  //     if(!userData.sip){
  //         return
  //     }
  //     syncSip(userData.sip);
  //     closeLogin();
  // }

  const handleOpenTask = (e: any) => {
    const status = e.target.checked;
    if (status) {
      window.localStorage.setItem(gConst.STORAGE_OPEN_TASK, 'true');
    } else {
      window.localStorage.removeItem(gConst.STORAGE_OPEN_TASK);
    }
  };

  const initStatic = async () =>{
    const logo = await CDN.brandLogo();
    if (logo) {
      setLogo(logo);
    }

  }  

  const toggleChecking = (e: any, name: any) => {
    const status = e.target.checked;
    if (status) {
      window.localStorage.setItem(name, 'true');
    } else {
      window.localStorage.removeItem(name);
    }
  };
  const handleChangeParentHostName = (e: ChangeEvent<HTMLSelectElement>) => {
    if (+e.target.value !== 0) {
      setParentHostName(e.target.value);
      setMobileParrentHost(e.target.value);
    } else {
      setParentHostName('');
      setMobileParrentHost('');
      window.localStorage.removeItem(gConst.STORAGE_PARRENT_HOST_NAME);
    }
    window.location.href = window.location.origin;

  };

  return (
    <div className="Login">
      <button className="text-2xl font-bold text-white block text-right ml-auto mr-4 mt-4" onClick={closeLogin}>
        X
      </button>
      <img src={logo} className="mx-auto mt-4 mb-12 w-40 h-16" alt="logo" title="logo" />
      
      <div className="text-xl">
        <div className="flex gap-2">
          <p>Full name: </p>
          <p className="font-bold">{userData.fullname}</p>
        </div>
      </div>
      {/* <table>
                <tr>
                    <th>Full Name</th>
                    <td>{userData.fullname}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{userData.email}</td>
                </tr>
                <tr>
                    <th>Extension</th>
                    <td>{userData.sip ? userData.sip.extension : ""}</td>
                </tr>
            </table> */}
      <div className="py-2 flex flex-col gap-3">
        <Checkbox isChecked={window.localStorage.getItem(gConst.STORAGE_GCALLS_DISABLE_AUTO_FORMAT_NUMBER) === 'true'} onClick={(e: any) => toggleChecking(e, gConst.STORAGE_GCALLS_DISABLE_AUTO_FORMAT_NUMBER)} data={'Disable Auto Format Number'} id={gConst.STORAGE_GCALLS_DISABLE_AUTO_FORMAT_NUMBER} />
        {!isMobile(window) && !!listOptions && !!listOptions.length
          ? listOptions.map((ele: any, i: any) => {
              return <Checkbox isChecked={window.localStorage.getItem(ele.name) === 'true'} onClick={(e: any) => toggleChecking(e, ele.name)} data={ele.label} id={ele.name} />;
            })
          : null}
        {isMobile(window) && (
          <select onChange={handleChangeParentHostName} value={listParentHostName[parentHostName]} className="w-full text-xl p-4 text-black rounded-lg outline-none bg-neutral-100 my-0">
            <option value={0}> - </option>
            {Object.keys(listParentHostName).map((key) => (
              <option selected={listParentHostName[key] === parentHostName} value={listParentHostName[key]}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </option>
            ))}
          </select>
        )}
      </div>
      <button className="btn" onClick={handleLogoutKeycloak}>
        Logout
      </button>
    </div>
  );
}

export default InfoPage;
